import { FunctionComponent, useCallback } from "react";
import ConsultaCPF from "./ConsultaCPF";

const ExerccioFindoSUGEP: FunctionComponent = () => {

  const onVerificaoDeElegibilidadeClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='tituloText3']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onDeclaraoDeInexistnciaClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='tituloText2']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onInformaesSobreOClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='tituloText1']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onAlertaDeGolpesClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='tituloText']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onDvidasEContatoClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='rodaP']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onBotonEducaDFClick = useCallback(() => {
    window.open("https://educadf.se.df.gov.br/");
  }, []);

  const onBotaoDeclaracaoClick = useCallback(() => {
    window.open("https://www.gdfnet.df.gov.br");
  }, []);

  const onBotaoDeclaracao1Click = useCallback(() => {
    window.open("https://www.educacao.df.gov.br/wp-conteudo/uploads/2021/06/declaracao_inexistencia_desistencia_acao_judicial_dea.pdf");
  }, []);

  const onBotaoGovbrContainerClick = useCallback(() => {
    window.open("https://sso.acesso.gov.br/");
  }, []);

  const onAboutUsTextClick = useCallback(() => {
    window.open("https://www.educacao.df.gov.br");
  }, []);

  const onGalleryTextClick = useCallback(() => {
    window.open("https://www.educacao.df.gov.br/aviso-de-privacidade-lgpd/");
  }, []);

  const onProjectsTextClick = useCallback(() => {
    window.open("https://www.gdfnet.df.gov.br");
  }, []);

  const onTimelineTextClick = useCallback(() => {
    window.open("https://portaldeservicos.se.df.gov.br");
  }, []);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-11 px-0 pb-0 box-border gap-[3px_0px] tracking-[normal]">
      <section className="w-[1007px] flex flex-row items-start justify-start pt-0 pb-[103px] pr-0 pl-[31px] box-border max-w-full text-center text-[40px] text-black font-inter mq750:pb-[67px] mq750:box-border">
        <div className="flex-1 flex flex-col items-start justify-start gap-[17px_0px] max-w-full">
          <header className="self-stretch flex flex-row items-start justify-start pt-0 pb-[5px] pr-0 pl-[11px] box-border max-w-full text-left text-sm text-gray font-inter">
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-1.5 pl-0 box-border gap-[0px_16px] max-w-full mq1050:hidden">
              <div
                className="relative font-medium whitespace-nowrap cursor-pointer"
                onClick={onVerificaoDeElegibilidadeClick}
              >
                Verificação de Elegibilidade
              </div>
              <div
                className="relative font-medium whitespace-nowrap cursor-pointer"
                onClick={onDeclaraoDeInexistnciaClick}
              >
                {" "}
                Declaração de Inexistência
              </div>
              <div
                className="relative tracking-[-0.3px] font-medium whitespace-nowrap cursor-pointer"
                onClick={onInformaesSobreOClick}
              >
                Informações sobre o Pagamento
              </div>
              <div
                className="relative tracking-[0.1px] font-medium whitespace-nowrap cursor-pointer"
                onClick={onAlertaDeGolpesClick}
              >
                Alerta de Golpes
              </div>
              <div className="flex flex-col items-start justify-start py-0 pr-3.5 pl-0">
                <div
                  className="relative tracking-[0.1px] font-medium cursor-pointer"
                  onClick={onDvidasEContatoClick}
                >
                  Contato
                </div>
              </div>
              <button
                className="cursor-pointer [border:none] p-[11px] bg-mediumseagreen-100 w-[114px] rounded flex flex-row items-center justify-center box-border hover:bg-mediumseagreen-200"
                onClick={onBotonEducaDFClick}
              >
                <div className="h-[38px] w-[114px] relative rounded bg-mediumseagreen-100 hidden" />
                <div className="relative text-smi font-inter text-white text-center z-[1]">
                  LOGIN
                </div>
              </button>
            </div>
          </header>
          <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
            <div className="flex flex-col items-center justify-start gap-[32px_0px] max-w-full mq750:gap-[32px_0px]">
              <div className="flex flex-row items-start justify-start py-0 pr-[23px] pl-0">
                <img
                  className="h-[69px] w-[235px] relative object-cover"
                  loading="lazy"
                  alt=""
                  src="/logo@2x.png"
                />
              </div>
              <h1 className="m-0 w-[756px] relative text-inherit leading-[60px] font-medium font-inherit inline-block mq450:text-5xl mq450:leading-[36px] mq1050:text-13xl mq1050:leading-[48px]">
                <span>{`Regularização de Pagamento de `}</span>
                <span className="text-mediumseagreen-100">
                  Despesas de Exercícios Anteriores
                </span>
              </h1>
            </div>
          </div>
          <div className="w-[941px] relative text-base-3 leading-[23px] text-lightslategray inline-block max-w-full box-border pr-5">
            A SEEDF está comprometida em regularizar os pagamentos de Despesas
            de Exercícios Anteriores (DEA), conhecidos como “exercícios findos”,
            beneficiando aproximadamente 25 mil servidores ativos, aposentados,
            pensionistas e professores substitutos. A iniciativa visa assegurar
            os direitos de nosso pessoal por meio de um processo administrativo
            claro e eficiente.
          </div>
        </div>
      </section>
      <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[21px] box-border max-w-full text-center text-13xl-5 text-gray font-inter">
        <div className="flex-1 bg-whitesmoke-100 flex flex-col items-center justify-start pt-[43px] pb-[78px] pr-[29px] pl-5 box-border gap-[29px] max-w-full mq450:pt-7 mq450:pb-[51px] mq450:box-border">
          <div className="w-[1267px] h-[351px] relative bg-whitesmoke-100 hidden max-w-full" />
          <div className="flex flex-col items-center justify-start gap-[17px_0px] max-w-full">
            <div className="w-[714px] flex flex-row items-start justify-start py-0 pr-0 pl-[31px] box-border max-w-full">
              <div
                className="flex-1 relative leading-[49px] font-medium inline-block max-w-full z-[3] mq450:text-lgi mq450:leading-[29px] mq1050:text-7xl mq1050:leading-[39px]"
                data-scroll-to="tituloText3"
              >
                Consulta do Servidor
              </div>
            </div>
            <div className="w-[788px] relative text-base leading-[23px] inline-block z-[1] text-lightslategray">
              <span>{`Para verificar sua elegibilidade ao pagamento das DEA, informe os dados e clique em “Verificar”. `}</span>
              <span className="text-mediumseagreen-100">
                É crucial que os interessados não tenham solicitado pagamento
                pela via judicial ou declarem sua desistência de qualquer ação
                judicial existente.
              </span>
            </div>
          </div>
          <div className="w-[558px] flex flex-row items-start justify-start max-w-full text-left text-smi">
            <div className="w-[508px] flex flex-col items-start justify-start gap-[11px_0px] max-w-full">
              <div className="relative z-[1]"> CPF</div>
              <ConsultaCPF></ConsultaCPF>
            </div>
          </div>
        </div>
      </section>
      <section
          className="w-[1141px] flex flex-row items-start justify-start pt-0 pb-[55px] pr-12 pl-5 box-border max-w-full text-center text-13xl-5 text-gray font-inter lg:pr-6 lg:box-border">
        <div className="flex-1 flex flex-col items-center justify-start gap-[24px_0px] max-w-full">
          <div
            className="self-stretch relative leading-[49px] font-medium mq450:text-lgi mq450:leading-[29px] mq1050:text-7xl mq1050:leading-[39px]"
            data-scroll-to="tituloText2"
          >
            Declaração de Inexistência ou Desistência de Ação Judicial
          </div>
          <div className="flex flex-col items-center justify-start gap-[21px_0px] max-w-full text-base text-lightslategray">
            <div className="flex flex-col items-end justify-start gap-[15px_0px] max-w-full">
              <div className="w-[946px] relative leading-[23px] inline-block">
                <span>{`A realização dos pagamentos dos créditos de “exercícios findos” somente poderá ser efetivada aos interessados que comprovarem `}</span>
                <span className="font-semibold text-tomato">NÃO</span>
                <span>
                  {" "}
                  ter solicitado os pagamentos pela via Judicial e/ou declararem
                  a desistência de Ação Judicial com o mesmo fim.
                </span>
              </div>
              <div className="flex flex-row items-start justify-start py-0 px-0.5 box-border max-w-full text-gray">
                <div className="w-[929px] relative leading-[23px] inline-block shrink-0 max-w-full">
                  A comprovação do requisito acima, deve ser realizada por meio
                  do preenchimento da "Declaração de Inexistência ou de
                  Desistência de Ação Judicial", um documento importante para
                  validar a autorização de pagamentos, pela via Administrativa.
                </div>
              </div>
            </div>
            <div className="flex flex-row items-start justify-start py-0 pr-0 pl-4">
              <button
                className="cursor-pointer [border:none] py-[11px] pr-[46px] pl-[47px] bg-mediumseagreen-100 rounded flex flex-row items-center justify-center whitespace-nowrap hover:bg-mediumseagreen-200"
                onClick={onBotaoDeclaracaoClick}
              >
                <div className="h-[38px] w-[234px] relative rounded bg-mediumseagreen-100 hidden" />
                <b className="relative text-smi font-inter text-white text-center z-[1]">
                  Preencher Declaração
                </b>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="w-[1033px] flex flex-row items-start justify-start pt-0 px-5 pb-[53px] box-border max-w-full text-left text-lg text-royalblue font-inter lg:pb-[34px] lg:box-border mq750:pb-5 mq750:box-border mq1050:pb-[22px] mq1050:box-border">
        <div className="w-[834px] flex flex-col items-start justify-start gap-[28px_0px] max-w-full">
          <div className="flex flex-row items-start justify-start py-0 px-[9px] text-center text-13xl">
            <h1 className="m-0 relative text-inherit font-medium font-inherit mq450:text-lgi mq1050:text-7xl">
              Passo-a-Passo
            </h1>
          </div>
          <div className="flex flex-col items-start justify-start pt-0 px-0 pb-3.5 box-border gap-[38px_0px] max-w-full mq450:gap-[38px_0px]">
            <div className="w-[764px] flex flex-col items-end justify-start gap-[24px_0px] max-w-full">
              <div className="self-stretch relative leading-[23px]">
                <b>
                  <span>1º PASSO</span>
                </b>
                <span className="text-base text-gray">
                  <b>:</b>
                  <span className="font-medium">
                    {" "}
                    servidor deverá entrar no site e preencher o CPF e sua
                    senha.
                  </span>
                </span>
              </div>
              <img
                className="w-[728px] relative max-h-full object-cover max-w-full"
                loading="lazy"
                alt=""
                src="/1-passo-imagem@2x.png"
              />
            </div>
            <div className="flex flex-row items-start justify-start py-0 pr-0 pl-5 box-border max-w-full">
              <div className="flex flex-col items-start justify-start gap-[22px_0px] max-w-full">
                <div className="w-[775px] relative leading-[23px] inline-block">
                  <b>2º PASSO</b>
                  <span className="text-base font-medium text-gray">
                    : clicar em “Pagamento de Despesas de Exercício Anteriores –
                    DEA” após “Declaração” e realizar o preenchimento dos dados
                    solicitados.
                  </span>
                </div>
                <div className="w-[756px] flex flex-row items-start justify-start py-0 px-4 box-border max-w-full">
                  <img
                    className="h-[306px] flex-1 relative max-w-full overflow-hidden object-cover"
                    loading="lazy"
                    alt=""
                    src="/2-passo-imagem@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[15px] box-border max-w-full">
            <div className="flex-1 flex flex-col items-end justify-start gap-[19px_0px] max-w-full">
              <div className="self-stretch flex flex-row items-start justify-start py-0 pr-[33px] pl-[5px] box-border max-w-full">
                <div className="w-[781px] relative leading-[23px] inline-block shrink-0 max-w-full">
                  <b>3º PASSO</b>
                  <span className="text-base font-medium text-gray">
                    : quanto ao campo “Não possuo ação judicial para fins de
                    recebimento de qualquer valor referente ao pagamento de
                    Despesa de Exercício Anterior - DEA, constituída no Processo
                    (...)”
                  </span>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start pt-0 pb-3 pr-[33px] pl-0 box-border max-w-full text-base text-gray">
                <div className="flex-1 flex flex-col items-start justify-start gap-[13px_0px] max-w-full">
                  <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[5px] box-border max-w-full">
                    <div className="flex-1 relative leading-[35px] inline-block max-w-full">
                      <ul className="m-0 text-inherit pl-[21px]">
                        <li className="mb-0">
                          <span className="text-gray">
                            <span className="font-medium font-inter">
                              SEI nº 00080-00112968/2020-41 para os servidores
                            </span>
                            <b className="font-inter">{` `}</b>
                          </span>
                          <b className="font-inter">
                            <span className="text-tomato">
                              ATIVOS, APOSENTADOS E PENSIONISTAS E CONTRATO TEMPORÁRIO.
                            </span>
                          </b>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <img
                    className="w-[724px] relative max-h-full object-cover max-w-full"
                    loading="lazy"
                    alt=""
                    src="/3-passo-imagem@2x.png"
                  />
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[13px] box-border max-w-full text-base text-gray">
                <div className="w-[814px] relative leading-[23px] inline-block shrink-0 max-w-full">
                  <span className="font-medium">{`Caso o servidor queira `}</span>
                  <b className="text-royalblue">DESISTIR</b>
                  <span className="font-medium">{` de ação judicial que busca o recebimento de `}</span>
                  <b className="text-royalblue">DEA</b>
                  <span className="font-medium">{` ou "exercício findo", `}</span>
                  <span className="font-medium">
                    {" "}
                    deverá desistir primeiramente perante o judiciário e posteriormente acessar o portal do servidor
                    com o número do processo judicial inicial e do número do processo de desisitência.
                  </span>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[31px_0px] max-w-full mq450:gap-[31px_0px]">
                <div className="w-[814px] relative leading-[23px] inline-block">
                  <b>4º PASSO</b>
                  <span className="text-base font-medium text-gray">
                    : Após o preenchimento deverá clicar em “CONFIRMAR” e após
                    inserir a senha novamente e clicar em “OK”. que irá aparecer
                    em uma nova aba a declaração em formato PDF para impressão.
                  </span>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[34px_0px] max-w-full text-base text-gray mq450:gap-[34px_0px]">
                  <div className="self-stretch relative leading-[23px] font-semibold">
                    Irá aparecer em uma nova aba a declaração em formato PDF
                    para impressão.
                  </div>
                  <div className="w-[752px] flex flex-row items-start justify-start py-0 px-3.5 box-border max-w-full">
                    <img
                      className="h-[306px] flex-1 relative max-w-full overflow-hidden object-cover"
                      loading="lazy"
                      alt=""
                      src="/4-passo-imagem2@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="self-stretch flex flex-col items-start justify-start gap-[13px_0px] max-w-full text-center text-5xl text-white font-inter">
        <div className="self-stretch h-[47px] bg-darkgray-200 flex flex-row items-center justify-start pt-0 px-[121px] pb-0 box-border relative gap-[0px_11px] max-w-full mq750:pl-[30px] mq750:pr-[30px] mq750:box-border mq1050:pl-[60px] mq1050:pr-[60px] mq1050:box-border">
          <div className="self-stretch w-[1267px] relative bg-darkgray-200 hidden max-w-full z-[0]" />
          <img
            className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full object-cover z-[1]"
            loading="lazy"
            alt=""
            src="/rectangle-2@2x.png"
          />
          <div className="mb-[-2px] flex flex-col items-start justify-start py-0 pr-3 pl-0 box-border max-w-[calc(100%_-_282px)] shrink-0">
            <h3 className="mt-[-2px] m-0 relative text-inherit leading-[49px] font-semibold font-inherit z-[2] mq450:text-lgi mq450:leading-[39px]">
              Não tem acesso ao Portal do Servidor ?
            </h3>
          </div>
          <button
            className="cursor-pointer [border:none] p-0 bg-[transparent] w-[153px] flex flex-row items-center justify-center z-[2]"
            onClick={onBotaoDeclaracao1Click}
          >
            <div className="flex-1 rounded bg-tomato flex flex-row items-center justify-center py-[11px] pr-1.5 pl-2 whitespace-nowrap">
              <div className="h-[38px] w-[153px] relative rounded bg-tomato hidden" />
              <b className="flex-1 relative text-smi font-inter text-white text-center z-[1]">
                Declaração Manual
              </b>
            </div>
          </button>
          <div
            className="w-[107px] flex flex-row items-center justify-center cursor-pointer z-[2] text-smi"
            onClick={onBotaoGovbrContainerClick}
          >
            <div className="flex-1 rounded bg-orange flex flex-row items-center justify-center py-[11px] pr-1 pl-1.5">
              <div className="h-[38px] w-[107px] relative rounded bg-orange hidden" />
              <b className="flex-1 relative z-[1]">GOV.BR</b>
            </div>
          </div>
        </div>
        <div className="w-[1113px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-left text-base text-lightslategray">
          <div className="w-[871px] relative leading-[23px] inline-block shrink-0 max-w-full">
            <span>{`Para aqueles que não têm acesso ao Portal do Servidor, existe uma alternativa prática: é possível completar a declaração de forma manual, acessando o documento. Após o preenchimento, basta realizar a assinatura digital através do sistema GOV.BR e, em seguida, encaminhar o documento assinado para o endereço eletrônico `}</span>
            <span className="font-medium text-mediumseagreen-100">
              deadipae.sugep@se.df.gov.br
            </span>
            <span>
              . Esta medida assegura que todos tenham a oportunidade de
              participar do processo, garantindo a inclusão e facilitando o
              acesso aos procedimentos necessários.
            </span>
          </div>
        </div>
      </section>
      <section className="self-stretch flex flex-row items-start justify-start pt-0 pb-[23px] pr-px pl-0 box-border max-w-full text-center text-13xl-5 text-gray font-inter">
        <div className="flex-1 bg-whitesmoke-100 flex flex-col items-center justify-start pt-12 pb-3.5 pr-[35px] pl-5 box-border gap-[33px] max-w-full mq750:gap-[33px] mq750:pt-[31px] mq750:pb-5 mq750:box-border">
          <div className="w-[1268px] h-[697px] relative bg-whitesmoke-100 hidden max-w-full" />
          <div className="flex flex-row items-start justify-start py-0 pr-[5px] pl-0 box-border max-w-full">
            <div className="flex flex-col items-center justify-start gap-[16px_0px] max-w-full">
              <div className="w-[794px] flex flex-row items-start justify-start py-0 pr-0 pl-1.5 box-border max-w-full">
                <div
                  className="flex-1 relative leading-[49px] font-medium inline-block max-w-full z-[1] mq450:text-lgi mq450:leading-[29px] mq1050:text-7xl mq1050:leading-[39px]"
                  data-scroll-to="tituloText1"
                >
                  Informações sobre o Pagamento
                </div>
              </div>
              <div className="w-[932px] relative text-base leading-[23px] text-lightslategray inline-block z-[1]">
                Os pagamentos dos créditos de exercícios findos dependerão da
                disponibilidade orçamentária. A SEEDF esclarece que todos os
                esforços estão sendo feitos para realizar os pagamentos de forma
                justa e dentro do possível, respeitando o cronograma
                estabelecido pela Secretaria de Estado de Economia (SEEC).
              </div>
            </div>
          </div>
          <div className="w-[1070px] flex flex-row flex-wrap items-start justify-start gap-[0px_48px] max-w-full text-left text-lg text-darkslategray mq750:gap-[0px_48px]">
            <div className="flex-1 flex flex-row items-start justify-start gap-[0px_19px] min-w-[216px] max-w-full mq450:flex-wrap">
              <div className="h-[49px] w-[45px] relative">
                <div className="absolute top-[25.2px] left-[0px] rounded-[50%] bg-mediumseagreen-300 w-6 h-6 z-[1]" />
                <img
                  className="absolute top-[0px] left-[4px] w-[41px] h-[39px] object-cover z-[2]"
                  loading="lazy"
                  alt=""
                  src="/searchalt2regular240-1@2x.png"
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[17px_0px]">
                <div className="relative font-medium z-[1]">
                  Preenchimento Declaração
                </div>
                <div className="w-[267px] h-[246px] relative text-mini leading-[22px] text-lightslategray inline-block shrink-0 z-[1]">
                  A correta submissão desta declaração é um pré-requisito
                  indispensável para que a SEEDF possa proceder com a elaboração
                  do processo de pagamento das referidas despesas em aberto.
                  Esta etapa é fundamental para assegurar a transparência e
                  eficiência na regularização dos pagamentos pendentes.
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-row items-center justify-end relative min-w-[213px] max-w-full">
              <div className="h-[46px] w-[43px] absolute !m-[0] top-[3px] left-[0px]">
                <div className="absolute top-[22.2px] left-[0px] rounded-[50%] bg-mediumseagreen-300 w-6 h-6 z-[1]" />
                <img
                  className="absolute top-[0px] left-[2px] w-[41px] h-[39px] object-cover z-[2]"
                  loading="lazy"
                  alt=""
                  src="/searchalt2regular240-2@2x.png"
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[17px_0px]">
                <div className="relative font-medium z-[1]">Sobre</div>
                <div className="w-[264px] h-[327px] relative text-mini leading-[22px] text-lightslategray inline-block shrink-0 z-[1]">
                  As despesas mencionadas referem-se a montantes que, por
                  determinação, deveriam ter sido disponibilizados aos
                  servidores em anos anteriores. Devido à não efetivação desses
                  pagamentos na época devida, a Administração Pública formaliza
                  o reconhecimento da dívida por meio de um documento oficial.
                  No entanto, é importante ressaltar que a liquidação dessa
                  dívida está condicionada à disponibilidade de recursos
                  orçamentários para tal fim.
                </div>
              </div>
            </div>
            <div className="w-[314px] flex flex-row items-center justify-end relative">
              <div className="h-[47px] w-11 absolute !m-[0] top-[2px] left-[0px]">
                <div className="absolute top-[23.2px] left-[0px] rounded-[50%] bg-mediumseagreen-300 w-6 h-6 z-[1]" />
                <img
                  className="absolute top-[0px] left-[3px] w-[41px] h-[39px] object-cover z-[2]"
                  loading="lazy"
                  alt=""
                  src="/searchalt2regular240-3@2x.png"
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[17px_0px]">
                <div className="relative font-medium z-[1]">
                  Processo de Pagamento
                </div>
                <div className="w-[250px] h-[429px] relative text-mini leading-[22px] text-lightslategray inline-block shrink-0 z-[1]">
                  A legislação permite a realização de ajustes financeiros que
                  abrangem até cinco anos retroativos, conforme estabelecido
                  pelo artigo 54 da Lei nº 9784/99. A Secretaria de Educação
                  compromete-se a efetuar tais pagamentos assim que houver
                  recursos orçamentários alocados para essa finalidade. É
                  importante destacar que o cronograma para a liberação desses
                  pagamentos está sujeito à definição e disponibilização de
                  verbas pela Secretaria de Estado de Economia (SEEC), o que
                  determinará o momento adequado para o recebimento dos créditos
                  em questão.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col items-start justify-start pt-0 px-5 pb-[21px] box-border gap-[27px_0px] max-w-full text-center text-base text-lightslategray font-inter">
        <div className="flex flex-row items-start justify-end py-0 pr-[70px] pl-[86px] box-border max-w-full text-13xl-5 text-black mq750:pl-[21px] mq750:box-border mq1050:pl-[43px] mq1050:pr-[35px] mq1050:box-border">
          <div
            className="w-[788px] relative leading-[49px] font-medium inline-block shrink-0 max-w-full mq450:text-lgi mq450:leading-[29px] mq1050:text-7xl mq1050:leading-[39px]"
            data-scroll-to="tituloText"
          >
            Atenção aos Golpes
          </div>
        </div>
        <div className="flex flex-row items-start justify-start py-0 pr-0 pl-3 box-border max-w-full text-tomato">
          <div className="w-[932px] relative leading-[23px] font-medium inline-block shrink-0 max-w-full">
            Mantenha-se vigilante contra tentativas de fraudes. A SEEDF enfatiza
            que todas as operações são conduzidas unicamente através de seus
            canais oficiais. Encorajamos a desconfiança e a precaução com
            pedidos de pagamentos ou compartilhamento de informações pessoais
            por vias não autorizadas.
          </div>
        </div>
        <div className="flex flex-row items-start justify-start py-0 px-0.5 box-border max-w-full text-left">
          <div className="w-[932px] relative leading-[23px] inline-block shrink-0 max-w-full">
            É crucial lembrar que as comunicações e procedimentos oficiais da
            Secretaria ocorrem exclusivamente por meio de nossos canais
            verificados, incluindo o site oficial, o Portal do Servidor e
            contatos de e-mail institucionais. Priorizamos a segurança e a
            integridade das informações em todas as nossas interações.
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[21px_0px] max-w-full text-left">
          <div className="w-[932px] relative leading-[23px] inline-block">
            A SEEDF destaca que não há cobrança de qualquer pagamento ou taxa
            para o registro dos interessados no processo de solicitação para
            autorização de pagamentos de despesas de exercícios anteriores.
            Nossa prioridade é a transparência e a facilidade de acesso aos
            direitos dos servidores, sem custos adicionais.
          </div>
          <div className="flex flex-row items-start justify-start py-0 pr-0 pl-0.5 box-border max-w-full">
            <div className="w-[932px] relative leading-[23px] inline-block shrink-0 max-w-full">
              Caso você receba qualquer solicitação suspeita para efetuar
              pagamentos ou fornecer dados pessoais ou financeiros por meios não
              oficiais, tais como mensagens de texto, chamadas telefônicas ou
              e-mails não vinculados às instituições, recomendamos que não
              responda a essas solicitações. A sua segurança é de extrema
              importância; por favor, reporte tais ocorrências aos canais
              oficiais para a devida investigação e orientação.
            </div>
          </div>
        </div>
      </section>
      <footer
        className="self-stretch bg-gray flex flex-row items-start justify-start pt-[23px] px-[120px] pb-[27px] box-border gap-[76px] max-w-full text-left text-mini text-darkgray-100 font-inter lg:flex-wrap lg:gap-[76px] lg:pl-[60px] lg:pr-[60px] lg:box-border mq450:pt-5 mq450:pb-5 mq450:box-border mq750:gap-[76px] mq750:pl-[30px] mq750:pr-[30px] mq750:box-border"
        data-scroll-to="rodaP"
      >
        <div className="h-[408px] w-[1268px] relative bg-gray hidden max-w-full" />
        <div className="flex flex-col items-start justify-start gap-[8px_0px] max-w-full">
          <div className="flex flex-row items-start justify-start pt-0 px-0 pb-[11px]">
            <img
              className="h-[43px] w-[119px] relative object-cover z-[1]"
              loading="lazy"
              alt=""
              src="/logo@2x.png"
            />
          </div>
          <div className="flex flex-row items-start justify-start pt-0 px-0 pb-2 text-whitesmoke-200">
            <div className="relative leading-[22px] z-[1]">
              <p className="m-0 font-medium">
                Diretoria de Pagamento de Pessoal (Dipae)
              </p>
              <p className="m-0">E-mail: deadipae.sugep@se.df.gov.br</p>
              <p className="m-0">Telefone: (61) 3318-2875</p>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start pt-0 px-1 pb-[22px] box-border max-w-full">
            <div className="w-[328px] relative tracking-[-1px] leading-[22px] inline-block max-w-full z-[1]">
              <p className="m-0">
                <span className="font-medium font-inter">Endereço</span>
                <span>
                  Secretaria de Estado de Educação do Distrito Federal
                </span>
              </p>
              <p className="m-0">
                SCN Qd. 6 Bl. B – Edifício Venâncio 3000, Asa Norte,
              </p>
              <p className="m-0">Brasília - DF, 70716-900</p>
            </div>
          </div>
          <div className="relative leading-[22px] z-[1]">
            <p className="m-0">Direitos Autorais</p>
            <p className="m-0">
              © 2024 Secretaria de Estado de Educação do Distrito Federal. Todos
              os direitos reservados.
            </p>
          </div>
          <div className="relative leading-[22px] inline-block max-w-full z-[1]">
            <p className="m-0">Créditos das Imagens:</p>
            <p className="m-0">
              All packs created and Illustrations by Storyset
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start pt-9 px-0 pb-0 text-base-3 text-white">
          <div className="flex flex-col items-start justify-start gap-[24px_0px]">
            <div className="relative font-medium z-[1]">Links Úteis</div>
            <div className="flex flex-col items-start justify-start gap-[19px_0px] text-mini text-darkgray-100">
              <div
                className="relative cursor-pointer z-[1]"
                onClick={onAboutUsTextClick}
              >
                Site da SEEDF
              </div>
              <div
                className="relative cursor-pointer z-[1]"
                onClick={onGalleryTextClick}
              >
                Aviso de Privacidade
              </div>
              <div
                className="relative cursor-pointer z-[1]"
                onClick={onProjectsTextClick}
              >
                Portal do Servidor - GDF
              </div>
              <div
                className="relative cursor-pointer z-[1]"
                onClick={onTimelineTextClick}
              >
                Portal de Chamados
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ExerccioFindoSUGEP;
