import React, {useState,useRef} from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputMask from 'react-input-mask';
import {validate} from "cpf-check";
import ReCAPTCHA from "react-google-recaptcha";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface ResultadoConsulta {
    status: string;
    name?: string;
    cpf?: string;
    valorReceber?: number;
    mensagem?: string;
}

const ConsultaCPF: React.FC = () => {

    const [cpf, setCpf] = useState<string>('');
    const [resultado, setResultado] = useState<ResultadoConsulta | null>(null);
    const [mensagem, setMensagem] = useState<string>('');
    const [open, setOpen] = React.useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isValidToken, setIsValidToken] = useState(false);

    const handleCpf = (e:any)=>{
        const cpfLimpo = e.target.value.replace(/\D/g, '');
        setCpf(cpfLimpo);
        setIsValid(validate(cpfLimpo));
        setIsValidToken(captchaRef?.current?.getValue() != "");
    };

    const handleRecaptcha = (token:string | null)=>{
        if (token){
            setIsValidToken(true);
        }else{
            setIsValidToken(false);
        }
    };

    const consultarCPF = async (token:string | null | undefined) => {
        try {
            const response = await axios.post<ResultadoConsulta>(`${window.env.REACT_APP_API_URL}/api/cpf/consulta`,{token,cpf});
            setResultado(response.data);
            setMensagem('');
        } catch (error) {
            limparCampos();
            setMensagem('Não foi encontrado CPF nesta busca!');
        }
    }

    const limparCampos = async () => {
        setCpf('');
        setResultado(null);
        setMensagem('');
    }

    const captchaRef = useRef<ReCAPTCHA>(null);
    const handleSubmit = (e:any) => {
        e.preventDefault();
        const token: string | null | undefined = captchaRef?.current?.getValue();
        captchaRef?.current?.reset();
        consultarCPF(token);
        setOpen(true);
        setCpf("");
        setIsValid(false);
    }

    const handleClose = () => setOpen(false);

   
    return (

<form onSubmit={handleSubmit}>
    <div>
        <div className="self-stretch flex flex-row flex-wrap items-end justify-start gap-[0px_30px] max-w-full">
            <div
                className="flex-1 rounded bg-white box-border flex flex-row items-center justify-start pt-[11px] px-5 pb-3 min-w-[225px] max-w-full z-[1] border-[1px] border-solid border-steelblue-100">
                <div
                    className="h-10 w-[250px] relative rounded bg-white box-border hidden max-w-full border-[1px] border-solid border-steelblue-100"/>
                <InputMask
                    mask="999.999.999-99"
                    className="w-[258px] [border:none] [outline:none] font-inter text-smi bg-[transparent] h-4 relative text-lightslategray text-left flex items-center z-[2]"
                    placeholder="Informe seu CPF"
                    value={cpf}
                    onChange={handleCpf}
                    onFocus={(e) => limparCampos()}/>
                {!isValid && cpf.length === 11 && <p style={{ color: 'red', marginTop: '5px' }}>CPF inválido</p>}
            </div>
            <Popup trigger={
                <button type={"submit"}
                    disabled={(!isValid) || (!isValidToken)}
                    className={`cursor-pointer p-[5px] bg-steelblue-100 w-[122px] rounded flex flex-row items-center justify-center box-border z-[1] hover:bg-steelblue-200 ${ isValid && isValidToken ? '' : 'opacity-50 pointer-events-none'}`}>
                    <div className="h-[38px] w-[122px] relative rounded bg-steelblue-100 hidden"/>
                    <b className="h-7 w-[98px] relative text-smi flex font-inter text-white text-center items-center justify-center shrink-0 z-[1]">
                        Verificar
                    </b>
                </button>} position="right center">

                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open} >
                    <DialogTitle className="bg-whitesmoke-100" sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Consulta DEA
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }} >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            {resultado && (
                               <>
                                    <p>{resultado.status}</p>
                                    {resultado.valorReceber != undefined && resultado.valorReceber <= 0.03 ? (
                                        <p>Para o servidor(a) <strong>{resultado.name}</strong>, não foi identificado nenhum valor a ser recebido.</p>
                                    ) : (
                                        <p>Para o servidor(a) <strong>{resultado.name}</strong>, identificamos o seguinte valor a ser recebido: <strong> {resultado.valorReceber?.toLocaleString('pt-BR', {style:'currency',currency:'BRL'})}</strong></p>
                                    )}
                                    {resultado.mensagem}
                                </>
                            )}
                            {mensagem && <p>{mensagem}</p>}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <button
                            className="cursor-pointer [border:none] py-[11px] pr-[46px] pl-[47px] bg-mediumseagreen-100 rounded flex flex-row items-center justify-center whitespace-nowrap hover:bg-mediumseagreen-200"
                            onClick={handleClose}>
                            <span className= "text-white">SAIR</span>
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </Popup>
        </div>
    </div>
    <div className="pt-5">
        <ReCAPTCHA onChange={handleRecaptcha} sitekey={window.env.REACT_APP_SITE_KEY} ref={captchaRef}/>
    </div>
</form>

    );
};

export default ConsultaCPF;